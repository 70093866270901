import { defineStore } from "pinia";

export const useNavBarModule = defineStore("navbar", {
  state: () => {
    return {
      _isDarkMode: false,
      _theme: "basic",
      _userDarkModeTheme: "basic",
      openTutorial: false,
      dialogVisible: false,
    };
  },
  getters: {
    isDarkMode: (state) => state._isDarkMode,
    theme: (state) => state._theme,
    userDarkModeTheme: (state) => state._userDarkModeTheme,
  },
  actions: {
    setDarkMode(mode) {
      this._isDarkMode = mode;
      this._userDarkModeTheme = mode ? "dark" : this._theme;
    },
    setNewTheme(theme) {
      this._theme = theme;
    },
    setOpenTutorial(value) {
      this.openTutorial = value;
    },
    toggleDialog(value) {
      // Hinzugefügt
      this.dialogVisible = value;
    },
  },
});
