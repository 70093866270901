<template>
  <div class="header">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img
            src="@/assets/park-an.png"
            alt=""
            height="40"
            class="d-inline-block align-text-top navbar-logo"
          />
        </a>
        <div class="collapse navbar-collapse" id="navbarText">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/parkdruck"
                >Parkdruck</router-link
              >
            </li>
            <!--
            <li class="nav-item test-item">
              <router-link class="nav-link disabled" to="/dateivisualisierung">Visualisierung</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link disabled" to="/dateien">Dateien</router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="redirectToAccountManagement"
                >Account <i class="bi bi-box-arrow-up-right"></i
              ></a>
            </li>
            -->
          </ul>
          <span class="navbar-text">
            <button
              type="button"
              class="btn btn-outline-primary btn-change-map"
              data-bs-toggle="modal"
              data-bs-target="#themeModal"
              v-if="!isDarkMode"
            >
              Kartenstil <i class="bi bi-map"></i>
            </button>

            <button
              type="button"
              class="btn btn-outline-primary btn-change-map"
              @click="sendSignalOpenTutorial"
            >
              Tutorial <i class="bi bi-info-circle"></i>
            </button>

            <input
              type="checkbox"
              v-model="isDarkMode"
              class="btn-check"
              id="btn-check-outlined"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="btn-check-outlined"
              ><i class="bi bi-moon-stars-fill"></i
            ></label>
            <!--
            <button type="button" class="btn btn-primary btn-logout">
              <a
                @click.prevent="$zitadel.oidcAuth.signOut"
                v-if="$zitadel.oidcAuth.isAuthenticated"
                href="#"
                class="logout"
                >Logout</a
              >
            </button>
            -->
          </span>

          <!-- Modal with id:themeModal -->
          <ChangeMapStyleDialogue />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { computed } from "vue";
import { useNavBarModule } from "../../store/navBarModule";
import ChangeMapStyleDialogue from "../dialogues/ChangeMapStyleDialogue.vue";

export default {
  name: "MyNavbar",
  components: {
    ChangeMapStyleDialogue: ChangeMapStyleDialogue,
  },
  setup() {
    const navBarStore = useNavBarModule();
    const isDarkMode = computed({
      get: () => {
        return navBarStore.isDarkMode;
      },
      set: (val) => {
        setNewDarkMode(val);
      },
    });

    const sendSignalOpenTutorial = () => {
      navBarStore.setOpenTutorial(true);
    };

    const selectedTheme = computed({
      get: () => {
        return navBarStore.theme;
      },
      set: (val) => {
        setNewTheme(val);
      },
    });

    const setNewDarkMode = (val) => {
      navBarStore.setDarkMode(val);
    };

    const setNewTheme = (val) => {
      navBarStore.setNewTheme(val);
    };

    return {
      navBarStore,
      isDarkMode,
      selectedTheme,
      sendSignalOpenTutorial,
    };
  },
  methods: {
    redirectToAccountManagement() {
      var url = "http://localhost:8081/ui/console/v1/users/me/profile";
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/kodis.scss";

// SPECIFIC STYLES
.navbar {
  padding: 0;
  background-color: #f5f7fa;
}

.navbar-brand {
  min-height: 60px;
  text-align: center;
  width: 70px;
}

.nav-item {
  width: 115px;
  justify-content: center;
}

.nav-item > a:hover {
  color: $kodis-color;
}

.navbar-nav > .nav-item > .router-link-active {
  color: $kodis-color !important;
}

.navbar-light .nav-item.active .nav-link,
.navbar-light .nav-item .nav-link:active,
.navbar-light .nav-item .router-link-active,
.navbar-light .nav-item .nav-link:focus,
.navbar-light .nav-item:hover .nav-link {
  color: $kodis-color !important;
}

.navbar-nav > .nav-item > a {
  justify-content: center;
}

.test-item {
  margin-right: 45px;
}

.navbar-text {
  padding-right: 10px;
}

.btn-logout {
  margin-left: 15px;
}

.btn-change-map {
  margin-right: 15px;
}

.logout {
  text-decoration: none;
  color: white !important;
}

.navbar-text > a {
  text-decoration: none;
}
</style>
