import { createApp } from "vue";
import App from "./App.vue";
import VueLogger from "vuejs3-logger";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/dark/css-vars.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@/scss/kodis.scss";

import { createPinia } from "pinia";
//import zitadelAuth from "@/services/zitadelAuth";
import router from "./router";

import * as jose from "jose";
//import { moment } from "moment";
import axios from "axios";

// OpenTelemetry Web Auto Instrumentation
import { BatchSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-proto";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { Resource } from "@opentelemetry/resources";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { registerInstrumentations } from "@opentelemetry/instrumentation";

async function initializeApp() {
  const resource = Resource.default().merge(
    new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: "frontend",
      [SemanticResourceAttributes.SERVICE_VERSION]: "0.1.0",
    })
  );

  const provider = new WebTracerProvider({ resource: resource });
  provider.addSpanProcessor(
    new BatchSpanProcessor(
      new OTLPTraceExporter({
        url: process.env.VUE_APP_OTEL_ENDPOINT + "/v1/traces",
      })
    )
  );
  provider.register({ contextManager: new ZoneContextManager() });

  registerInstrumentations({
    instrumentations: [new getWebAutoInstrumentations()],
  });

  // INIT PINIA
  const pinia = createPinia();

  // ZITADEL AUTHENTICATION
  // ONLY FOR EUROPAEISCHE MOBILITAETSWOCHE (UIH!)
  // Command for transforming PKCS 1 (using downloaded json and add it to an empty PEMfile) to PKCS 8
  // openssl pkcs8 -topk8 -in privateKey.pem -out privateKeyParsed.pem -nocrypt
  const pem = process.env.VUE_APP_PRIVATE_KEY;
  const alg = "RS256";
  let privateKey = await jose.importPKCS8(pem, alg);

  let encodedJwt = await new jose.SignJWT()
    .setProtectedHeader({
      alg: alg,
      kid: process.env.VUE_APP_KEY_ID,
    })
    .setSubject(process.env.VUE_APP_USER_ID)
    .setIssuer(process.env.VUE_APP_USER_ID)
    .setAudience(process.env.VUE_APP_API_URL)
    .setExpirationTime("5min")
    .setIssuedAt()
    .sign(privateKey);

  axios
    .post(
      process.env.VUE_APP_API_URL + "/oauth/v2/token",
      new URLSearchParams({
        grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
        scope: "openid",
        assertion: encodedJwt,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then((response) => {
      const app = createApp(App);
      for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component);
      }
      app.use(router);
      app.use(VueLogger);
      //app.use("bootstrap");
      app.use(ElementPlus);
      app.use(pinia);
      app.config.globalProperties.$zitadel = response.data;
      app.mount("#app");
    })
    .catch((error) => {
      console.log("Startup not okay: ", error);
    });
}

initializeApp();
