import { createRouter, createWebHistory } from "vue-router";
import zitadelAuth from "../services/zitadelAuth";

const routes = [
  // MAIN PAGE
  { path: "", redirect: "/parkdruck" },

  // SERVICE PAGES
  {
    path: "/parkdruck",
    name: "parkdruck",
    component: () => import("../views/navbar/ParkingPressureView.vue"),
    //meta: {
    //  authName: zitadelAuth.oidcAuth.authName,
    //},
  },
  //{
  //    path: '/dateivisualisierung',
  //    name: 'dateivisualisierung',
  //    component: () => import('../views/navbar/FileVisualizationView.vue'),
  //    meta: {
  //        authName: zitadelAuth.oidcAuth.authName,
  //    },
  //},
  //{
  //    path: '/dateien',
  //    name: 'dateien',
  //    component: () => import('../views/navbar/FileManagementView.vue'),
  //    meta: {
  //        authName: zitadelAuth.oidcAuth.authName,
  //    },
  //},

  // FOOTER
  {
    path: "/impressum",
    name: "impressum",
    component: () => import("../views/footer/LegalNotice.vue"),
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    component: () => import("../views/footer/Privacy.vue"),
  },
  //{ path: '/nutzung', name: 'nutzung', component: Terms, },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/footer/About.vue"),
  },
  //{ path: '/howto', name: 'howto', component: HowTo, },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
});

zitadelAuth.oidcAuth.useRouter(router);

export default router;
