import { createZITADELAuth } from "@zitadel/vue";

const zitadelAuth = createZITADELAuth(
  {
    project_resource_id: process.env.VUE_APP_ZITADEL_PROJECT_ID,
    client_id: process.env.VUE_APP_ZITADEL_CLIENT_ID,
    issuer: process.env.VUE_APP_ZITADEL_ISSUER,
  },
  undefined,
  0,
  undefined,
  {
    redirect_uri: process.env.VUE_APP_ZITADEL_REDIRECT_URI,
    response_type: "id_token token",
  }
);

// handle events
zitadelAuth.oidcAuth.events.addAccessTokenExpiring(function () {
  // eslint-disable-next-line no-console
  //console.log("access token expiring");
});

zitadelAuth.oidcAuth.events.addAccessTokenExpired(function () {
  // eslint-disable-next-line no-console
  //console.log("access token expired");
});

zitadelAuth.oidcAuth.events.addSilentRenewError(function (err) {
  // eslint-disable-next-line no-console
  //console.error("silent renew error", err);
});

zitadelAuth.oidcAuth.events.addUserLoaded(function (user) {
  // eslint-disable-next-line no-console
  //console.log("user loaded", user);
});

zitadelAuth.oidcAuth.events.addUserUnloaded(function () {
  // eslint-disable-next-line no-console
  //console.log("user unloaded");
});

zitadelAuth.oidcAuth.events.addUserSignedOut(function () {
  // eslint-disable-next-line no-console
  //console.log("user signed out");
});

zitadelAuth.oidcAuth.events.addUserSessionChanged(function () {
  // eslint-disable-next-line no-console
  //console.log("user session changed");
});

export default zitadelAuth;
