<template>
  <div
    class="modal fade"
    id="themeModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h5 class="modal-title">Kartenstil</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            id="closeMapStyleModal"
          ></button>
        </div>
        <!-- Modal Body -->
        <div class="modal-body">
          <p>Bitte wählen Sie ihren gewünschten Kartenstil aus.</p>

          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="mapStyle"
              id="mapStyleBasic"
              value="basic"
              v-model="selectedMapStyle"
              checked
            />
            <label class="form-check-label" for="mapStyleBasic">
              <b>Basic Stil</b>
              <br />
              Einfacher, funktionaler Kartenstil.
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="mapStyle"
              id="mapStyleQwant"
              value="qwant"
              v-model="selectedMapStyle"
            />
            <label class="form-check-label" for="mapStyleQwant">
              <b>Qwant Basic Stil</b>
              <br />
              Darstellung der Karten im Stil der Qwant-Suchmaschine mit Fokus
              auf POIs und weitere wichtige Informationen.
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="mapStyle"
              id="mapStylePositron"
              value="positron"
              v-model="selectedMapStyle"
            />
            <label class="form-check-label" for="mapStylePositron">
              <b>Positron Stil</b>
              <br />
              Heller, minimalistischer Kartenstil, der sich auf die Darstellung
              von Straßen und grundlegenden geografischen Merkmalen
              konzentriert.
            </label>
          </div>
        </div>
        <!-- Modal Footer -->
        <div class="modal-footer">
          <button
            @click="changeTheme()"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useNavBarModule } from "../../store/navBarModule";
export default {
  name: "ChangeMapStyleDialogue",
  data() {
    return {
      selectedMapStyle: "basic",
    };
  },
  setup() {
    const navBarStore = useNavBarModule();
    return {
      navBarStore,
    };
  },
  mounted() {
    this.localTheme = this.navBarStore.theme;
  },
  methods: {
    changeTheme() {
      this.navBarStore.setNewTheme(this.selectedMapStyle);
    },
  },
};
</script>

<style scoped></style>
