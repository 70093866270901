<template>
  <!-- The Modal -->
  <div
    class="modal fade"
    id="introModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    data-bs-target="#introModal"
  >
    <div
      class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl"
    >
      <div class="modal-content card">
        <!-- Modal Header -->
        <div class="modal-header border-0">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            @click="$emit('changeResetIntro')"
          ></button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <div
            id="introCarousel"
            class="carousel carousel-dark slide"
            data-bs-ride="false"
          >
            <div class="carousel-inner">
              <!-- SLIDE 1 -->
              <div class="carousel-item active" id="first-carousel-slide">
                <div class="container h-100">
                  <div class="row h-100 align-items-center text-center">
                    <div class="col align-middle">
                      <img
                        src="@/assets/park-an.png"
                        alt=""
                        height="400"
                        class="d-inline-block align-text-top navbar-logo"
                      />
                    </div>
                    <div class="col">
                      <h1>Willkommen in Park-an</h1>
                      <p>
                        Mit Park-an können Sie den Parkdruck für verschiedene
                        Städte und Regionen auf Basis der
                        OpenStreetMap-Datenbank berechnen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- SLIDE 2 -->
              <div class="carousel-item">
                <div class="container h-100">
                  <div class="row h-100 align-items-center text-center">
                    <div class="col align-middle">
                      <img
                        src="@/assets/intro/01_neue_analyse.gif"
                        alt="Neue Analyse"
                        height="500"
                        class="d-inline-block align-text-top navbar-logo"
                      />
                    </div>
                    <div class="col">
                      <p>Beginnen Sie eine neue Parkdruckanalyse mit</p>
                      <button
                        type="button"
                        class="btn btn-outline-primary dummytest button-results"
                        disabled
                      >
                        neue Analyse <i class="bi bi-box-arrow-up-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- SLIDE 3 -->
              <div class="carousel-item">
                <div class="container h-100">
                  <div class="row h-100 align-items-center text-center">
                    <div class="col align-middle">
                      <img
                        src="@/assets/intro/02_analysevarianten.gif"
                        alt="Analysevarianten"
                        height="500"
                        class="d-inline-block align-text-top navbar-logo"
                      />
                    </div>
                    <div class="col">
                      <p>
                        Sie können zwischen 3 Analysevarianten für die
                        Berechnung des Parkdrucks wählen:
                      </p>
                      <p>
                        Parkdruckindikator Gebäude,<br />
                        Parkdruckindikator POI und<br />
                        Parkdruckindikator POI mit Belegungsdaten.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- SLIDE 4 -->
              <div class="carousel-item">
                <div class="container h-100">
                  <div class="row h-100 align-items-center text-center">
                    <div class="col align-middle">
                      <img
                        src="@/assets/intro/03_stadt_raster_details.gif"
                        alt="Stadt, Raster und Details"
                        height="500"
                        class="d-inline-block align-text-top navbar-logo"
                      />
                    </div>
                    <div class="col">
                      <p>
                        Für alle Analysevarianten müssen Sie den Namen der Stadt
                        bzw. der Region eingeben.
                      </p>

                      <p>
                        Außerdem können Sie das zugrundeliegende Raster für die
                        Berechnung des Parkdrucks auswählen. Mithilfe der
                        Rasterauflösung können Sie den Detailgrad der Berechnung
                        anpassen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- SLIDE 5 -->
              <div class="carousel-item">
                <div class="container h-100">
                  <div class="row h-100 align-items-center text-center">
                    <div class="col align-middle">
                      <img
                        src="@/assets/intro/04_pois.gif"
                        alt="POIs"
                        height="500"
                        class="d-inline-block align-text-top navbar-logo"
                      />
                    </div>
                    <div class="col">
                      <p>
                        Für die Analysevarianten Parkdruckindikator POI sowie
                        Parkdruckindikator POI mit Belegungsdaten können Sie die
                        für die Berechnung zu berücksichtigenden Point of
                        Interests (POIs) festlegen.
                      </p>
                      <p>
                        Dabei können Sie alle POIs einer Gruppe auf einmal oder
                        einzelne POIs innerhalb einer Gruppe auswählen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- SLIDE 6 -->
              <div class="carousel-item">
                <div class="container h-100">
                  <div class="row h-100 align-items-center text-center">
                    <div class="col align-middle">
                      <img
                        src="@/assets/intro/05_berechnung.gif"
                        alt="Berechnung"
                        height="500"
                        class="d-inline-block align-text-top navbar-logo"
                      />
                    </div>
                    <div class="col">
                      <p>
                        Anschließend können Sie die Berechnung der Analyse
                        starten mit
                      </p>
                      <button
                        type="button"
                        class="btn btn-outline-primary"
                        disabled
                      >
                        Parkdruck berechnen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- SLIDE 7 -->
              <div class="carousel-item">
                <div class="container h-100">
                  <div class="row h-100 align-items-center text-center">
                    <div class="col align-middle">
                      <img
                        src="@/assets/intro/06_visualisierung.gif"
                        alt="Visualisierung"
                        height="500"
                        class="d-inline-block align-text-top navbar-logo"
                      />
                    </div>
                    <div class="col">
                      <p>
                        Bereits durchgeführte Analysen können Sie jederzeit
                        abrufen, löschen und anzeigen lassen mit
                      </p>
                      <div class="btn-group me-3" role="group">
                        <button
                          type="button"
                          class="btn btn-outline-danger button-results"
                          disabled
                        >
                          Löschen
                        </button>
                        <button
                          type="button"
                          class="btn btn-outline-primary button-results"
                          disabled
                        >
                          Anzeigen
                        </button>
                        <button
                          type="button"
                          class="btn btn-outline-secondary button-results"
                          disabled
                        >
                          Update<i class="bi bi-arrow-clockwise"></i>
                        </button>
                      </div>
                      <hr />

                      <p>
                        Für die Analyseergebnisse
                        <i>Parkdruck Indikator: POI</i> sowie
                        <i>Parkdruck Indikator: POI mit Belegungsdaten</i>
                        können Sie die POIs anzeigen lassen.
                      </p>
                      <hr />

                      <p>Sie können außerdem den Kartenstil ändern mit</p>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-change-map"
                        disabled
                      >
                        Kartenstil
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- SLIDE 8 -->
              <div class="carousel-item" id="last-carousel-slide">
                <div class="container h-100">
                  <div class="row h-100 align-items-center text-center">
                    <div class="col align-middle">
                      <img
                        src="@/assets/park-an.png"
                        alt=""
                        height="400"
                        class="d-inline-block align-text-top navbar-logo"
                      />
                    </div>
                    <div class="col">
                      <p>
                        Und jetzt viel Spaß bei der Berechnung von Parkdruck in
                        Park-an!
                      </p>

                      <button
                        type="button"
                        class="btn btn-primary me-md-2"
                        data-bs-dismiss="modal"
                        id="popover_test"
                        @click="$emit('changeResetIntro')"
                      >
                        Tutorial abschließen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#introCarousel"
              data-bs-slide="prev"
              v-show="showPrevButtonCarousel"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#introCarousel"
              data-bs-slide="next"
              v-show="showNextButtonCarousel"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <!-- Modal Footer -->
        <div class="modal-footer border-0"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel } from "bootstrap";

export default {
  name: "CarouselModal",
  props: {
    showPrevButtonCarousel: Boolean,
    showNextButtonCarousel: Boolean,
  },
  methods: {
    changeDisplayCarouselButtons(event) {
      this.$emit("changeDisplayCarouselButtons", event);
    },
    resetCarouselToFirstSlide() {
      const myCarousel = document.getElementById("introCarousel");
      if (myCarousel) {
        const carouselInstance = new Carousel(myCarousel);
        carouselInstance.to(0); // Move to the first slide (index 0)
      }
    },
  },
  mounted() {
    const myCarousel = document.getElementById("introCarousel");
    if (myCarousel) {
      myCarousel.addEventListener(
        "slide.bs.carousel",
        this.changeDisplayCarouselButtons
      );
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/kodis.scss";

#introModal {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
}

.carousel-item {
  height: 500px;
}

.carousel-control-prev,
.carousel-control-next {
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  height: auto;
}

.carousel-control-prev {
  left: 15px;
}

.carousel-control-next {
  right: 15px;
}
</style>
