<!--
  APP COMPONENT
  the foundation of the frontend service
  it has the navigation bar at the top
  it has the footer bar at the bottom
  it has the main conent bar between in the middle
  in the main content bar the component by its matching url will display by router-view
-->

<template>
  <div class="app" :data-bs-theme="userDarkModeTheme">
    <div class="loading-spinner-wrapper screen-size-warning">
      <div class="container text-center h-100">
        <div class="row align-items-center h-100">
          <div class="col">
            <p>
              Ihr Bildschirm unterstützt nicht die erforderliche Mindestbreite
              (1000px). Bitte verwenden Sie einen geeigneten Bildschirm.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Navbar -->
    <MyNavBar />
    <!-- MAIN DIV -->
    <!-- for services, account and files -->
    <div class="main">
      <router-view></router-view>
    </div>

    <!-- FOOTER -->
    <div class="container footer">
      <div class="row footer-content">
        <!-- ROUTER LINKS -->
        <div class="col">
          <router-link class="footer-content-pages" to="/impressum"
            >Impressum</router-link
          >
        </div>
        <div class="col">
          <router-link class="footer-content-pages" to="/datenschutz"
            >Datenschutz</router-link
          >
        </div>
        <!--<div class="col"><router-link class="footer-content-pages" to="/nutzung">Nutzungsbedingungen</router-link></div>-->
        <div class="col">
          <router-link class="footer-content-pages" to="/about"
            >Über</router-link
          >
        </div>
        <!--<div class="col">
          <router-link class="footer-content-pages" to="/howto"
            >How To</router-link
          >
        </div>-->
      </div>
    </div>
  </div>
  <Vidle
    v-if="useVidle"
    @idle="onidle"
    :duration="300"
    :loop="false"
    v-show="None"
  />
  <IntroCarouselModal
    ref="introCarouselModal"
    :showPrevButtonCarousel="showPrevButtonCarousel"
    :showNextButtonCarousel="showNextButtonCarousel"
    @changeResetIntro="changeResetIntro"
    @changeDisplayCarouselButtons="changeDisplayCarouselButtons"
  />
</template>

<script>
import { computed } from "vue";
import MyNavbar from "./components/navbar/Navbar.vue";
import { useNavBarModule } from "./store/navBarModule";
import Vidle from "v-idle";
import IntroCarouselModal from "./components/dialogues/IntroCarouselView.vue";
import { Modal, Carousel } from "bootstrap";

export default {
  name: "AppPage",
  components: {
    MyNavBar: MyNavbar,
    Vidle: Vidle,
    IntroCarouselModal: IntroCarouselModal,
  },
  data() {
    return {
      markerList: [],
      showServices: false,
      showAccount: false,
      trips_json: Object,
      eventShowParkdruck: Object,
      show: true,
      resetIntro: true,
      showPrevButtonCarousel: false,
      showNextButtonCarousel: true,
      useVidle: false,
    };
  },
  setup() {
    // INIT NAV BAR
    const navBarStore = useNavBarModule();
    const userDarkModeTheme = computed(() => navBarStore.userDarkModeTheme);
    const openIntroModal = computed(() => navBarStore.openTutorial);

    return {
      navBarStore,
      userDarkModeTheme,
      openIntroModal,
    };
  },
  mounted() {
    let introModal = new Modal(document.getElementById("introModal"), {});
    introModal.show();
  },
  watch: {
    userDarkModeTheme(newVal) {
      if (newVal === "dark") {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    },
    openIntroModal(newVal) {
      if (newVal) {
        this.resetIntro = false;
        this.$refs.introCarouselModal.resetCarouselToFirstSlide();

        let closeMapStyleModal = document.getElementById("closeMapStyleModal");
        closeMapStyleModal.click();

        let closeParkingPressureModalButton = document.getElementById(
          "closeParkingPressureModal"
        );
        closeParkingPressureModalButton.click();

        let introModal = new Modal(document.getElementById("introModal"), {});
        introModal.show();

        this.navBarStore.setOpenTutorial(false);
      }
    },
  },
  methods: {
    changeDisplayCarouselButtons(event) {
      if (event.relatedTarget.id === "first-carousel-slide") {
        this.showPrevButtonCarousel = false;
        this.showNextButtonCarousel = true;
      } else if (event.relatedTarget.id === "last-carousel-slide") {
        this.showPrevButtonCarousel = true;
        this.showNextButtonCarousel = false;
      } else {
        this.showPrevButtonCarousel = true;
        this.showNextButtonCarousel = true;
      }
    },
    changeResetIntro() {
      this.resetIntro = true;
      this.startIdleCheck();
    },
    startIdleCheck() {
      this.useVidle = true;
    },
    onidle() {
      if (this.resetIntro === true) {
        this.resetIntro = false;
        this.$refs.introCarouselModal.resetCarouselToFirstSlide();

        let closeMapStyleModal = document.getElementById("closeMapStyleModal");
        closeMapStyleModal.click();

        let closeParkingPressureModalButton = document.getElementById(
          "closeParkingPressureModal"
        );
        closeParkingPressureModalButton.click();

        let introModal = new Modal(document.getElementById("introModal"), {});
        introModal.show();
      }
    },
    resetCarouselToFirstSlide() {
      const myCarousel = document.getElementById("introCarousel");
      if (myCarousel) {
        const carouselInstance = new Carousel(myCarousel);
        carouselInstance.to(0);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/kodis.scss";

#introModal {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
}

.carousel-item {
  height: 500px;
}

.carousel-control-prev,
.carousel-control-next {
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  height: auto;
}

.carousel-control-prev {
  left: 15px;
}

.carousel-control-next {
  right: 15px;
}
</style>
